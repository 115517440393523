/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockDto } from '../models/BlockDto';
import type { DomainDto } from '../models/DomainDto';
import type { OrganizationDto } from '../models/OrganizationDto';
import type { SubjectAreaDto } from '../models/SubjectAreaDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NsiRestCommonService {
  /**
   * @returns BlockDto OK
   * @throws ApiError
   */
  public static getBlocks(): CancelablePromise<Array<BlockDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/block',
    });
  }
  /**
   * @param requestBody
   * @returns BlockDto OK
   * @throws ApiError
   */
  public static updateBlock(
    requestBody: BlockDto,
  ): CancelablePromise<BlockDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/nsi/block',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns BlockDto OK
   * @throws ApiError
   */
  public static addBlock(
    requestBody: BlockDto,
  ): CancelablePromise<BlockDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/block',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns SubjectAreaDto OK
   * @throws ApiError
   */
  public static getSubjectAreas(): CancelablePromise<Array<SubjectAreaDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/subject_areas',
    });
  }
  /**
   * @returns OrganizationDto OK
   * @throws ApiError
   */
  public static getOrganizations(): CancelablePromise<Array<OrganizationDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/organization',
    });
  }
  /**
   * @returns DomainDto OK
   * @throws ApiError
   */
  public static getDomains(): CancelablePromise<Array<DomainDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/domain',
    });
  }
  /**
   * Сброс кэшей по имени. Если имя не указано, сброс всего
   * @param cacheName
   * @returns void
   * @throws ApiError
   */
  public static clearCacheNsi(
    cacheName?: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/clear_cache',
      query: {
        'cacheName': cacheName,
      },
    });
  }
  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static deleteBlock(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/nsi/block/{id}',
      path: {
        'id': id,
      },
    });
  }
}
