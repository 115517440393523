/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JwtRefreshRequestDto } from '../models/JwtRefreshRequestDto';
import type { JwtRequestDto } from '../models/JwtRequestDto';
import type { JwtResponseDto } from '../models/JwtResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthRestCommonDpProfilesExtService {
  /**
   * Обновление токена
   * Обновление пары Access и Refresh токенов по Refresh токену
   * @param requestBody
   * @returns JwtResponseDto OK
   * @throws ApiError
   */
  public static refreshAuthenticationToken(
    requestBody: JwtRefreshRequestDto,
  ): CancelablePromise<JwtResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/refresh',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Токен не валиден или не удалось регенерировать пару access refresh токенов`,
      },
    });
  }
  /**
   * Аутентификация пользователя
   * Получение Access и Refresh токенов
   * @param requestBody
   * @returns JwtResponseDto Successfully retrieved
   * @throws ApiError
   */
  public static authenticate(
    requestBody: JwtRequestDto,
  ): CancelablePromise<JwtResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/authenticate',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Ошибка аутентификация`,
      },
    });
  }
}
