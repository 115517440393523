/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthUserDetailsDto } from '../models/AuthUserDetailsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthRestCommonService {
  /**
   * Получение информации о текущем пользователе (UserDetails info)
   * Метод возвращает все роли пользователя как Портала, так и Магазина данных, эта информация используется для отрисовки функционала на стороне Frontend. На стороне Backend используются только роли соответствующего продукта (Портала или Магазина)
   * @returns AuthUserDetailsDto Successfully retrieved
   * @throws ApiError
   */
  public static getUserDetails(): CancelablePromise<AuthUserDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/me',
      errors: {
        401: `Not auth`,
      },
    });
  }
}
