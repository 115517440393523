export const portfolioConfig = {
  pageSize: 6,
  filterToolQueryKey: 'tool',
  filterDomainQueryKey: 'domain',
  filterOrganizationQueryKey: 'organization',
  filterBlockQueryKey: 'block',
  sortQueryKey: 'sort',
  searchQueryKey: 'search',
  statusPortfolioQueryKey: 'status',
  showTotalCountInFilters: true,
  // Регулярное выражение для проверки валидных ссылок
  // urlRegex: /^https?:\/\/([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
  // urlRegex: /^https?:\/\/([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*(&amp;|[\w@?^=%&/~+#-]))?$/,
  urlRegex: /^https?:\/\//,
};
