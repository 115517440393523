import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../../hooks/hooks.ts';
import { portfolioPageSelectors } from '../../../../../../../store/reducers/portfolioPageSlice.ts';
import { selectParamsByKeys } from '../../../../../../../store/reducers/selectors.ts';
import FilterBarWithQuery, {
  FilterBarItemType,
} from '../../../../../../components/FilterBarWithQuery/FilterBarWithQuery.tsx';
import { portfolioConfig } from '../../../../../../configs/portfolioConfig.ts';

import classes from './PortfolioFilterBar.module.css';

const portfolioFiltersQueryKeys = [
  portfolioConfig.filterBlockQueryKey,
  portfolioConfig.filterToolQueryKey,
  portfolioConfig.filterDomainQueryKey,
  portfolioConfig.filterOrganizationQueryKey,
];

export const PortfolioFilterBar = () => {
  const [blockValues, toolValues, domainValues, organizationsValues] = useAppSelector(
    selectParamsByKeys(portfolioFiltersQueryKeys)
  );
  const filterItems = useAppSelector(portfolioPageSelectors.portfolioFilters);
  const [, setSearchParams] = useSearchParams();
  const [items, setItems] = useState<FilterBarItemType[]>([]);

  useEffect(() => {
    if (filterItems) {
      let newItems: FilterBarItemType[] = [
        ...toolValues.map((el) => ({
          title: filterItems.tools?.find((item) => item._uuid === el)?.name ?? '',
          uuid: el,
          queryKey: portfolioConfig.filterToolQueryKey,
        })),
        ...blockValues.map((el) => ({
          title: filterItems.blocks?.find((item) => item._uuid === el)?.name ?? '',
          uuid: el,
          queryKey: portfolioConfig.filterBlockQueryKey,
        })),
        ...domainValues.map((el) => ({
          title: filterItems.domain?.find((item) => item._uuid === el)?.name ?? '',
          uuid: el,
          queryKey: portfolioConfig.filterDomainQueryKey,
        })),
        ...organizationsValues.map((el) => ({
          title: filterItems.organizations?.find((item) => item._uuid === el)?.name ?? '',
          uuid: el,
          queryKey: portfolioConfig.filterOrganizationQueryKey,
        })),
      ];
      setItems(newItems);
    }
  }, [blockValues, toolValues, domainValues, filterItems]);

  const onSave = (items: FilterBarItemType[]) => {
    setSearchParams((prev) => {
      portfolioFiltersQueryKeys.map((el) => prev.delete(el));
      items.map((el) => {
        prev.append(el.queryKey, el.uuid);
      });
      return prev;
    });
  };

  return <FilterBarWithQuery items={items} onSave={onSave} customClass={classes.bar} />;
};
