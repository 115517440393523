import { AuthRestCommonDpProfilesExtService } from '../../generateAxios';

import { parseJwt } from './parseJwt.ts';

export const startRefreshToken = () => {
  try {
    const refreshTokenString = localStorage.getItem('refresh_token');
    const accessTokenString = localStorage.getItem('access_token');
    if (accessTokenString && refreshTokenString) {
      const accessToken = parseJwt(accessTokenString);
      const now = Date.now();
      const exp = accessToken.exp * 1000;
      const msToEnd = exp - now;
      if (msToEnd - 10000 > 0) {
        const refreshTokenWorker = new Worker(
          `${import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL}/timeWorker.js`
        );
        refreshTokenWorker.postMessage(msToEnd - 10000);
        refreshTokenWorker.onmessage = (event) => {
          if (event.data === 'time is over') {
            refreshTokenWorker.terminate();
            const token = localStorage.getItem('refresh_token') ?? '';
            if (token) {
              AuthRestCommonDpProfilesExtService.refreshAuthenticationToken({
                refresh_token: token,
              }).then((response) => {
                if (response.access_token)
                  localStorage.setItem('access_token', response.access_token);
                else localStorage.removeItem('access_token');
                if (response.refresh_token)
                  localStorage.setItem('refresh_token', response.refresh_token);
                else localStorage.removeItem('refresh_token');
                startRefreshToken();
              });
            }
          }
        };
      }
    }
  } catch (e) {
    console.log(`ошибка в обновлении токенов,`, e);
  }
};
