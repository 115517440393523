/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterUserDto } from '../models/FilterUserDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserRestCommonService {
  /**
   * @param filter
   * @param skipInactive
   * @returns FilterUserDto OK
   * @throws ApiError
   */
  public static findUsersFromLdap(
    filter: string,
    skipInactive: boolean = true,
  ): CancelablePromise<Array<FilterUserDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/find',
      query: {
        'filter': filter,
        'skipInactive': skipInactive,
      },
    });
  }
}
