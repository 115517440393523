import React from 'react';

import { Button } from '@consta/uikit/Button';

import { IconArrowPrevious } from '@consta/icons/IconArrowPrevious';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import {
  portfolioPageActions,
  portfolioPageSelectors,
} from '../../../../../store/reducers/portfolioPageSlice.ts';
import { FilterSideBarCheckBox } from '../../../../components/FilterSideBarCheckBox/FilterSideBarCheckBox.tsx';
import { SidebarWithResize } from '../../../../components/SidebarWithResize/SidebarWithResize.tsx';
import { portfolioConfig } from '../../../../configs/portfolioConfig.ts';

import classes from './PortfolioFiltersSideBar.module.css';
import { PortfolioFilterSideBarHeader } from './components/PortfolioFilterSideBarHeader.tsx';

export const PortfolioFiltersSideBar = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(portfolioPageSelectors.showSideBar);

  const handleCloseSidebar = () => {
    dispatch(portfolioPageActions.setShowSideBar(false));
  };

  return (
    <SidebarWithResize
      className={classes.sidebar}
      isOpen={open}
      hasOverlay={false}
      onClickOutside={handleCloseSidebar}
      onEsc={handleCloseSidebar}
    >
      <div className={classes.closeButton}>
        <Button
          onClick={handleCloseSidebar}
          size={'s'}
          view={'ghost'}
          iconRight={IconArrowPrevious}
        />
      </div>
      <PortfolioFilterSideBarHeader />
      <div className={classes.content}>
        <FilterSideBarCheckBox
          filterTitle={'Инструменты'}
          queryKey={portfolioConfig.filterToolQueryKey}
          itemsSelector={portfolioPageSelectors.toolsFilters}
          // showTotal={portfolioConfig.showTotalCountInFilters}
        />
        <FilterSideBarCheckBox
          filterTitle={'Блок'}
          queryKey={portfolioConfig.filterBlockQueryKey}
          itemsSelector={portfolioPageSelectors.blockFilters}
          // showTotal={portfolioConfig.showTotalCountInFilters}
        />
        <FilterSideBarCheckBox
          filterTitle={'Домен'}
          queryKey={portfolioConfig.filterDomainQueryKey}
          itemsSelector={portfolioPageSelectors.domainFilters}
          // showTotal={portfolioConfig.showTotalCountInFilters}
        />
        <FilterSideBarCheckBox
          filterTitle={'Организация'}
          queryKey={portfolioConfig.filterOrganizationQueryKey}
          itemsSelector={portfolioPageSelectors.organizationsFilters}
          // showTotal={portfolioConfig.showTotalCountInFilters}
        />
      </div>
    </SidebarWithResize>
  );
};
