import { useRef, useState } from 'react';

import { IconWorldFilled } from '@consta/uikit/IconWorldFilled';
import { Text } from '@consta/uikit/Text';

import { PortfolioDto } from '../../../../../generateAxios';

import classes from './PortfolioDomains.module.css';

export const PortfolioDomains = ({ portfolio }: { portfolio: PortfolioDto }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);
  const domainNames: string = portfolio.domains?.map((domain) => domain.name).join('; ') || '';
  if (!portfolio.domains || portfolio.domains.length === 0) return null;
  return (
    <div className={classes.container}>
      <IconWorldFilled view={'brand'} className={classes.icon} />
      <Text
        view={'secondary'}
        truncate={!showMore}
        ref={ref}
        onClick={() => {
          if (ref.current && ref.current?.scrollWidth > ref.current?.clientWidth) {
            setShowMore(true);
          }
        }}
        onMouseEnter={(e) => {
          e.currentTarget.setAttribute(
            'style',
            ref.current && ref.current?.scrollWidth > ref.current?.clientWidth
              ? 'cursor: pointer;'
              : ''
          );
        }}
      >
        {domainNames}
      </Text>
    </div>
  );
};
