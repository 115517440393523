/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterSelectorsKnowledgeDto } from '../models/FilterSelectorsKnowledgeDto';
import type { FiltersKnowledgeDto } from '../models/FiltersKnowledgeDto';
import type { KnowledgeCrudDto } from '../models/KnowledgeCrudDto';
import type { KnowledgeDto } from '../models/KnowledgeDto';
import type { KnowledgeDtoWithFilters } from '../models/KnowledgeDtoWithFilters';
import type { KnowledgeSelectorsDto } from '../models/KnowledgeSelectorsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class KnowledgeRestService {
  /**
   * Отредактировать knowledge
   * @param id
   * @param requestBody
   * @returns KnowledgeDto OK
   * @throws ApiError
   */
  public static updateKnowledge(
    id: string,
    requestBody: KnowledgeCrudDto,
  ): CancelablePromise<KnowledgeDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/knowledge/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Получить knowledge по фильтрам с паггинацией. POST версия фильтрации (GET)
   * @param requestBody
   * @returns KnowledgeDtoWithFilters OK
   * @throws ApiError
   */
  public static getAllKnowledgeByFilter(
    requestBody: FiltersKnowledgeDto,
  ): CancelablePromise<KnowledgeDtoWithFilters> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/knowledge',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Получить knowledge по списку uuid тегов
   * @param requestBody
   * @returns KnowledgeDto OK
   * @throws ApiError
   */
  public static getAllKnowledgeByTags(
    requestBody: Array<string>,
  ): CancelablePromise<Array<KnowledgeDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/knowledge/bytags',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Добавить knowledge
   * @param requestBody
   * @returns KnowledgeDto OK
   * @throws ApiError
   */
  public static saveKnowledge(
    requestBody: KnowledgeCrudDto,
  ): CancelablePromise<KnowledgeDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/knowledge/add',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Получить по guid knowledge (без файла)
   * @param guid
   * @returns KnowledgeDto OK
   * @throws ApiError
   */
  public static getKnowledgeById(
    guid: string,
  ): CancelablePromise<KnowledgeDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/knowledge/{guid}',
      path: {
        'guid': guid,
      },
    });
  }
  /**
   * Удалить knowledge по uid
   * @param guid
   * @returns void
   * @throws ApiError
   */
  public static deleteKnowledgeById(
    guid: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/knowledge/{guid}',
      path: {
        'guid': guid,
      },
    });
  }
  /**
   * Получить селекторы для knowledge
   * @returns KnowledgeSelectorsDto OK
   * @throws ApiError
   */
  public static getKnowledgeSelectors(): CancelablePromise<KnowledgeSelectorsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/knowledge/selectors',
    });
  }
  /**
   * Получить фильтры для knowledge
   * @returns FilterSelectorsKnowledgeDto OK
   * @throws ApiError
   */
  public static getKnowledgeFilters(): CancelablePromise<FilterSelectorsKnowledgeDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/knowledge/filters',
    });
  }
  /**
   * Получить knowledge по uuid тега
   * @param tag
   * @returns KnowledgeDto OK
   * @throws ApiError
   */
  public static getAllKnowledgeByTag(
    tag: string,
  ): CancelablePromise<Array<KnowledgeDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/knowledge/bytag',
      query: {
        'tag': tag,
      },
    });
  }
  /**
   * Получить knowledge по имени тега
   * @param tag
   * @returns KnowledgeDto OK
   * @throws ApiError
   */
  public static getAllKnowledgeByNameTag(
    tag: string,
  ): CancelablePromise<Array<KnowledgeDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/knowledge/bynametag',
      query: {
        'tag': tag,
      },
    });
  }
}
