/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullTeamItemDto } from '../models/FullTeamItemDto';
import type { TeamItemDto } from '../models/TeamItemDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserTeamRestService {
  /**
   * @returns TeamItemDto OK
   * @throws ApiError
   */
  public static getTeamItems(): CancelablePromise<Array<TeamItemDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/teams',
    });
  }
  /**
   * Обновление teamItem (SRV role)
   * @param requestBody
   * @returns TeamItemDto OK
   * @throws ApiError
   */
  public static updateTeamItem(
    requestBody: TeamItemDto,
  ): CancelablePromise<TeamItemDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/teams',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Добавление teamItem (SRV role)
   * @param requestBody
   * @returns TeamItemDto OK
   * @throws ApiError
   */
  public static addTeamItem(
    requestBody: TeamItemDto,
  ): CancelablePromise<TeamItemDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/teams',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Получить команды для главной страницы
   * @returns FullTeamItemDto OK
   * @throws ApiError
   */
  public static getTeamItemsWithHelpDesks(): CancelablePromise<Array<FullTeamItemDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/teams/full',
    });
  }
  /**
   * Сохранить команды для главной страницы (Block role)
   * @param requestBody
   * @returns FullTeamItemDto OK
   * @throws ApiError
   */
  public static saveTeamItemsWithHelpDesks(
    requestBody: FullTeamItemDto,
  ): CancelablePromise<FullTeamItemDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/teams/full',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Получить команду блока (по ID блока)
   * @param id
   * @returns TeamItemDto OK
   * @throws ApiError
   */
  public static getTeamItemsByBlockUuid(
    id: string,
  ): CancelablePromise<Array<TeamItemDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/teams/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Удаление teamItem (SRV role)
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static deleteteamItem(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/teams/{id}',
      path: {
        'id': id,
      },
    });
  }
}
